import { some } from "lodash";

import {
  SmartActionActionTypeEnumType,
  SmartActionEventTypeEnumType,
  SmartActionTriggerEnumType,
} from "@lib/data/schemas/smart-action";

import {
  EventUtilsType,
  GetAPIrelatedType,
  IsClientEventResourceType,
  WithSMSReminderType,
} from "./types";

export const eventCollectionName: { [key: string]: string } = {
  appointment: "appointments",
  file: "files",
  link: "links",
  folder: "folders",
  form: "forms",
  invoice: "invoices",
  packageInstance: "packageInstances",
  package: "packages",
};

export const eventUtils: EventUtilsType = (event) => {
  return {
    isTimmingAfter: event.timming === "after",
    isTimmingBefore: event.timming === "before",
    isTriggerApptStarts: ["appointment-starts", "event-starts"].includes(
      event.trigger
    ),
    isTriggerApptEnds: ["appointment-ends", "event-ends"].includes(
      event.trigger
    ),
    eventCollection: eventCollectionName[event.type],
  };
};

export const getAPIrelated: GetAPIrelatedType = (type) => {
  const SA_API_RELATED: Record<SmartActionActionTypeEnumType, string> = {
    "add-label": "smart-actions/add-label",
    "send-form": "smart-actions/send-form",
    "send-form-with-signed-contract":
      "smart-actions/send-form-with-signed-contract",
    "send-reminder": "smart-actions/send-reminder",
    "send-library-item": "smart-actions/send-library-item",
    "bill-scheduled-invoice": "smart-actions/bill-scheduled-invoice",
    "send-invoice": "smart-actions/send-invoice",
    "send-invoice-reminder-coach": "smart-actions/send-invoice-reminder-coach",
    "send-invoice-reminder-client":
      "smart-actions/send-invoice-reminder-client",
    "send-trial-will-end-reminder":
      "smart-actions/send-trial-will-end-reminder",
    "todo-assignment-reminder": "smart-actions/todo-assignment-reminder",
    "todo-due-date-reminder": "smart-actions/todo-due-date-reminder",
    "todo-week-reminder": "smart-actions/todo-week-reminder",
    "recurring-package-instance": "smart-actions/recurring-package-instance",
    "create-billable-request": "smart-actions/create-billable-request",
    "complete-package-instance": "smart-actions/complete-package-instance",
    "show-form": "",
    "send-form-reminder": "smart-actions/send-form-reminder",
  };
  const path = SA_API_RELATED[type] || "";
  return path;
};

export const getUseApi = (
  type: SmartActionActionTypeEnumType
): boolean | undefined => {
  if (
    type === "complete-package-instance" ||
    type === "create-billable-request" ||
    type === "send-form-reminder"
  )
    return true;
  return undefined;
};

export const withReminderSMS: WithSMSReminderType = (smartActions) =>
  some(smartActions, (item) => item?.action?.method?.includes("via-sms"));

export const isClientEventResource: IsClientEventResourceType = (eventType) =>
  ["form"].includes(eventType);

export const getEventTypeFromTrigger = (
  trigger: SmartActionTriggerEnumType
): SmartActionEventTypeEnumType | undefined => {
  const eventTriggerToTypeMap: Array<{
    triggers: Array<SmartActionTriggerEnumType>;
    type: SmartActionEventTypeEnumType;
  }> = [
    {
      triggers: ["invoice-is-due", "payment-is-completed"],
      type: "invoice",
    },
    {
      triggers: ["package-instance-is-created"],
      type: "packageInstance",
    },
    {
      triggers: [
        "package-is-booked",
        "payment-is-completed",
        "package-is-assigned",
        "package-sessions-completed",
      ],
      type: "package",
    },
  ];

  return eventTriggerToTypeMap.find(({ triggers }) =>
    triggers.includes(trigger)
  )?.type;
};
